.cam-modal {
    width: 80%;
    max-width: 80%;
}

@media only screen and (max-width: 900px) {
    .cam-modal {
        width: 96%;
        max-width: 96%;
    }
}

dt {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
}